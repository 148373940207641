@charset "UTF-8";

/*RESET*/

@import url('reset.css');
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
body {
	margin: 0px;
	padding: 0px;
	font-size: 12px;
	font-size: 0.8rem;
	font-family: 'Open Sans', sans-serif;
	color: #52565D;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	outline: none;
}

button {
	outline: none;
}

img {
	width: 100%;
	display: block
}

header {
	position: relative;
	padding: 10px 30px;
	z-index: 10;
}

header .logo {
	width: 300px;
	display: inline-block;
}

header nav {
	position: relative;
	display: block;
	padding: 20px 0px;
	float: right;
}

header nav li {
	display: inline-block;
	margin: 0px 0px;
}

header nav li a {
	padding: 10px 15px;
	display: block;
}

header nav li a:hover {
	opacity: 0.7
}

footer {
	background: #4E555C;
	padding: 20px;
	position: relative;
	z-index: 2;
}

footer .footIz {
	display: inline-block;
	position: relative;
}

footer .footIz img {
	max-width: 81px;
	display: inline-block;
	vertical-align: middle
}

footer .footIz .vr {
	height: 160px;
	width: 2px;
	border-radius: 2px;
	display: inline-block;
	vertical-align: middle;
	margin: 0px 10px;
	background: #fff
}

footer .footIz .info {
	display: inline-block;
	vertical-align: middle
}

footer .footIz .info a {
	color: #fff;
	display: block;
	margin: 14px 0px;
	font-weight: normal;
	font-size: 0.9rem;
}

footer .footIz .info a:hover {
	text-decoration: underline;
	color: #efefef;
}

footer .logoMercurio {
	width: 148px;
	float: right;
	margin-top: 50px;
}

.btn {
	background: #52565D;
	color: #fff;
}

.btRojo {
	background: #bb121b !important;
}

.btMenu {
	display: none;
}

* {
	box-sizing: border-box;
}

.clear {
	clear: both
}

.navSelect {
	background: #fff;
	color: #52565D;
	opacity: 1 !important
}

.menu {
	min-width: 250px;
	text-align: center;
	position: relative;
}

ul.subMenu {
	position: absolute;
	width: 100%;
	background: #bb121b;
	top: 45px;
	padding: 0px 0px 10px;
	display: none
}

.subMenu li a {
	color: #fff;
}

.subMenu li {
	display: block;
}

.subMenu hr {
	border: 0px;
	height: 1px;
	background: #fff;
	margin: 10px 30px;
}

.cuadro {
	width: 20px;
	height: 20px;
	border: solid 2px;
	display: inline-block;
	float: right;
	margin-top: -2px;
	transform: rotate(0deg);
	transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
}

.abierto {
	transform: rotate(45deg);
	transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
}

.textura {
	background: url(../images/textura.jpg);
}

.boxes {
	font-size: 0px;
	position: relative;
	overflow: hidden;
}

.box1 .box {
	width: 100%;
}

.box2 .box {
	width: 66.5%;
}

.content-topBtn.box2 .box {
	width: 49.2%;
}

.box4 .box {
	padding: 6px;
	margin-bottom: 50px;
}

.box {
	font-size: 1rem;
	position: relative;
	vertical-align: top;
	padding: 0;
}


/*Home*/

.redes li {
	list-style: none;
}

.redes a {
	background: rgba(255, 255, 255, 0.7);
	font-size: 20px;
	padding: 5px;
	margin: 5px 0px;
	display: block;
	color: #52565D;
}

.redes a:hover {
	background: #A01217;
	color: #fff;
}

.redes {
	position: fixed;
	right: 0px;
	top: 50%;
	margin-top: -77px;
	z-index: 100;
}

.banner1 {
	background-size: cover;
	width: 100%;
	height: 80vh;
	margin-top: -124px;
	min-height: 600px;
	padding: 1px 0px;
	z-index: -1;
}


/*.banner1 .banner {
    width: 280px;
    top: 50%;
    height: 220px;
    left: 50%;
    margin-top: -100px;
    background: rgba(255, 255, 255, 0.3);
    padding: 80px 0px 0px;
    position: absolute;
    margin-left: -150px;
    text-align: center;
}*/

.banner1 .banner {
	width: 100%;
	height: 100vh;
	padding: 1px 0px;
	z-index: -1;
}

.banner1 .banner:after {
	content: "";
	width: 100%;
	height: 100%;
	top: 0px;
	position: absolute;
	left: 0px;
	z-index: 9;
}

.banner1 .banner h2 {
	font-size: 1rem;
	margin: 0px;
	font-weight: 300;
	text-align: center;
}

.banner1 .banner strong {
	font-size: 3rem;
	color: #A01217;
}

.banner2 {
	position: relative;
	background: #A01217;
	color: #fff;
	/*height: 30vh;*/
	min-height: 150px;
	padding: 30px 0px 20px;
	overflow: hidden;
}

.banner2 .swiper-container {
	height: 150px;
}

.banner2 .banner {
	max-width: 500px;
	position: relative;
	margin: 0 auto;
}

.banner2 p {
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
}

.banner2 span {
	display: inline-block;
	font-size: 8rem;
	vertical-align: middle;
	color: #801815
}

.banner2 .pagination {
	right: 10px;
	width: 20px;
	bottom: inherit;
	top: 30px;
}

span.swiper-pagination-switch {
	border-radius: 0px;
	background: #801815;
	border: 0px;
	margin: 3px 0px;
}

span.swiper-pagination-switch.swiper-visible-switch.swiper-active-switch {
	background: #901718 !important;
}

.galeria {
	background: url(../images/textura.jpg);
	padding: 20px;
}

.galeria .wrap {
	max-width: 930px;
	position: relative;
	margin: 0 auto;
	font-size: 0px
}

.galeria a {
	display: inline-block;
	width: 31.3%;
	padding: 1%;
}

.adorno {
	background: url(../images/adorno1.png);
	width: 84px;
	height: 125px;
	top: 50%;
	margin-top: -62px;
	left: -84px;
	position: absolute
}

.adorno2 {
	background: url(../images/adorno2.png);
	right: -84px;
	left: inherit
}

.nosotros {
	position: fixed;
	top: 0px;
	bottom: 0px;
	height: 100%;
	z-index: 99;
	width: 100%;
	display: none;
}

.nosotros .wrap {
	width: 100%;
	position: absolute;
	margin: 0;
	background: rgba(255, 255, 255, 0.8);
	padding: 0px;
	top: 50%;
	margin-top: -150px;
}

.wrapper {
	max-width: 960px;
	margin: 0px auto;
	position: relative;
	padding: 50px 0px;
}

.wrapper p {
	text-align: justify;
}

.wrapper h1 {
	text-align: center;
	color: #A01217;
}

.nosotros .cerrar {
	width: 50px;
	height: 42px;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 2rem;
	cursor: pointer;
	display: block;
	background-position: 0px 0px;
	background-image: url("http://feelinbook.com/images/fancybox_sprite.png");
	z-index: 200;
	background-size: cover;
}

.nosotros .cerrar:hover {
	color: #A01217
}


/*ingresar*/

.ingresar {
	position: relative;
	background: url(../images/bgInicio.jpg) center;
	margin-top: -130px;
	padding: 30px 10px;
	min-height: 80vh;
	background-size: cover
}

.ingresar .wrap {
	max-width: 300px;
	text-align: center;
	margin: 130px auto 50px;
	position: relative;
	color: #fff;
}

.ingresar .wrap a {
	color: #fff;
}

.ingresar .wrap a:hover {
	text-decoration: underline
}

.ingresar img {
	margin: 30px auto;
}

.ingresar button {
	display: block;
	width: 100%;
	text-transform: inherit;
}


/*aditar portada*/

.editar_portada {
	max-width: 720px;
	position: relative;
	margin: 50px auto;
	background: #D7D8DC;
	padding: 30px 20px;
}

.editar_portada figure {
	width: 300px;
    position: relative;
    margin: 0 auto;
    padding: 0;
    float: right;
}

.editar_portada article:nth-of-type(1) {
	width: 60%;
	margin: 0 auto;
	margin-top: 5px;
}

.editar_portada article:nth-of-type(1) a {
	text-align: center;
	color: white;
	padding: 11px;
	font-size: 13px;
	font-weight: bold;
	border: 6px solid #D7D8DC;
}

.info-cover {
	line-height: 17px;
	margin: 10px 0px;
}

.editar_portada .marcoActivo {
	border: 7px solid #A8000C;
}

.editar_portada .thumbsMarcos aside {
	padding: 6px;
}

.editar_portada .removerMarco {
	background-image: url(../images/removerMarco.jpg);
}

.editar_portada .thumbsMarcos aside figure {
	width: 100%;
}

.editar_portada article:nth-of-type(1) a:nth-of-type(1) {
	background-color: #39424A;
}

.editar_portada article:nth-of-type(1) a:nth-of-type(2) {
	background-color: #A8000C;
}


/*revision de imagen*/

.revision_imagen {
	max-width: 720px;
	position: relative;
	margin: 50px auto;
	background: #D7D8DC;
	padding: 0 !important;
	margin-bottom: 0px;
}

.revision_imagen .canvas-container {
	width: 370px !important;
	height: 370px !important;
}

.revision_imagen .canvas-container #canvas {
	width: 370px !important;
	height: 370px !important;
}

.revision_imagen .canvas-container .upper-canvas {
	width: 370px !important;
	height: 370px !important;
}

.revision_imagen article:nth-of-type(1) aside {
	padding: 0;
}

.revision_imagen article:nth-of-type(1) aside form {
	padding: 30px 20px 0px 20px;
}

.revision_imagen article:nth-of-type(1) aside form input {
	width: 100%;
	border: 0;
	margin-bottom: 10px;
	padding: 6px 10px;
	outline: none;
}

.revision_imagen article:nth-of-type(1) aside form textarea {
	border: 0;
	margin-bottom: 10px;
	padding: 6px 10px;
	height: 80px;
	outline: none;
	resize: none;
}

.revision_imagen article:nth-of-type(1) aside .instruccionesRevision {
	padding: 0px 20px;
}

.revision_imagen article:nth-of-type(1) aside .instruccionesRevision h3 {
	margin: 0;
}

.revision_imagen article:nth-of-type(1) aside .instruccionesRevision p {
	margin: 0;
	font-size: 11px;
	line-height: 16px;
	padding-bottom: 10px;
	padding-right: 10px;
}

.revision_imagen article:nth-of-type(1) aside .botonesRevision {
	height: auto;
	padding: 39px 13px 0px 14px;
	text-align: center;
	margin-top: 30px;
}

.revision_imagen article:nth-of-type(1) aside .botonesRevision a {
	background: #5b5b5e;
	padding: 10px 11px;
	text-align: center;
	color: white;
	border: 5px solid #D7D8DC;
	font-size: 11px;
}

.revision_imagen article:nth-of-type(1) aside .botonesRevision a:nth-of-type(1) {
	background-image: url(../images/iconoRotar.svg);
	background-repeat: no-repeat;
	background-position: center right 4px;
	background-size: 13%;
}

.carruselRevision {
	width: 820px !important;
	border-top: 4px solid rgba(255, 255, 255, 0);
	border-bottom: 4px solid rgba(255, 255, 255, 0);
}

.carruselRevision .swiper-wrapper {
	background-color: #e3e4e7;
}

.carruselRevision .swiper-container .swiper-slide {
	padding: 20px 6px;
}

.carruselRevision .swiper-container {
	width: 91%;
	height: 180px;
}

.carruselRevision .arrow-left {
	left: 0px;
	right: auto;
	opacity: 1;
	position: relative;
	margin-top: -131px;
	width: 33px;
	height: 160px;
	cursor: pointer;
	background-repeat: no-repeat;
	z-index: 9;
	border-top: 75px solid transparent;
	border-bottom: 85px solid transparent;
	border-right: 27px solid #5b5b5e;
	top: -38px;
}

.carruselRevision .arrow-right {
	right: 0;
	opacity: 1;
	position: relative;
	margin-top: -201px;
	width: 33px;
	height: 160px;
	cursor: pointer;
	background-repeat: no-repeat;
	z-index: 9;
	float: right;
	border-top: 77px solid transparent;
	border-bottom: 84px solid transparent;
	border-left: 30px solid #5b5b5e;
}

.carruselRevision button {
	background: transparent;
	color: black;
	padding: 0;
}

.carruselRevision .swiper-slide-visible {
	//	padding: 10px;
}

.carruselRevision .botonesRev2 {
	width: 400px;
	margin: 0 auto;
	margin-bottom: 30px;
}

.carruselRevision .botonesRev2 a:nth-of-type(2) {
	background: #bb121b !important;
}

.carruselRevision .botonesRev2 a {
	background: #5b5b5e;
	padding: 10px;
	text-align: center;
	color: white;
	font-weight: bold;
	width: 49%;
	display: inline-block;
}


/*albumes*/

.albumes {
	max-width: 930px;
	position: relative;
	margin: 50px auto;
}

.albumes .boxes .album {
	padding: 0;
}

.boxInner {
	padding: 5px;
}

.boxInner .fotoAlbum {
	min-height: 223px;
	width: 100%;
	height: 100%;
	position: relative;
	display: block;
	background-image: url(../images/backpictureempty.svg);
	background-size: 80%;
	background-color: #ced0d5;
	background-position: center center;
	background-repeat: no-repeat;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
}

.fotoAlbumWhite {
	background: white !important;
}

.fotoAlbum img {
    margin: auto;
}

.album .info {
	margin: 5px 0px 0px;
	color: #fff;
	display: inline-block;
	width: 75%;
}

.info .titulo {
	background: #52565D;
	padding: 8px 10px;
	font-weight: 300;
	font-size: 14px;
}

.acciones a {
	display: inline-block;
	width: 49%;
	text-align: center;
	padding: 0px 5px;
	color: #fff;
	font-weight: 300;
	font-size: 14px;
	margin: 5px 0px;
}

.acciones a:first-of-type {
	border-right: solid 1px #fff;
}

.acciones a {
	display: inline-block;
	width: 47%;
	text-align: center;
	padding: 0px 5px;
	color: #fff;
	margin: 5px 0px;
}

.acciones a:hover {
	text-decoration: underline
}

.album .numeroFotos {
	width: 22%;
	float: right;
	margin: 5px 0px 0px 5px;
	text-align: center;
	height: 100%;
	padding: 14px 0px;
	box-shadow: 0 0 0 3px #52565D inset;
	font-size: 14px;
}

.album .numeroFotos span {
	display: block;
	font-weight: bold;
	margin-bottom: 3px;
	font-size: 16px;
}

.album .btn {
	padding: 17px 5px;
	text-align: center;
	display: block;
	font-size: 0.8em;
	padding-left: 25px;
}

.album .btn span {
	display: inline-block;
	vertical-align: middle;
	font-size: 2rem;
	padding-left: 10px;
	margin: 0;
	left: 7px;
	position: absolute;
	margin-top: -7px;
}

.album .btn p {
	display: inline-block;
	width: 90%;
	vertical-align: middle;
	margin: 0px;
}

.albumes .btRojo {
	display: block;
	text-align: center;
	padding: 10px;
	min-width: 30%;
	margin: 0px auto !important;
}

.listo .numeroFotos {
	background: #bb121b;
	color: #fff;
	box-shadow: none;
	border: none;
}

.listo .btn {
	background: #bb121b;
}

.box.box66 {
	width: 66.6%;
}

.albumes .btRojo {
	display: block;
	text-align: center;
	padding: 15px;
	font-size: 18px;
}

.albumes .btRojo span {
	margin-right: 20px
}

.albumes hr {
	margin: 15px 0px 0px;
	border: 0px;
	height: 5px;
	background: gray;
	border-radius: 19px;
	position: relative;
}

.logoRegistro .logo img {
	display: none;
}

.logoRegistro .logo {
	height: 102px;
}
.overlayInfo {
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	text-align: center;
	position: fixed;
	z-index: 99;
	display: none;
}
.personalizarInfo {
      max-width: 550px;
    height: auto;
    background: #B6B8BE;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 43%;
    z-index: 9;
    padding: 10px;
    width: 90%;
}
input.input_perfil {
    width: 59%;
}
.content_info .max-letter {
    padding: 5px 5px;
}
.personalizarInfo p {
	font-size: 1.2em;
}
.personalizarInfo p span {
	font-weight: bold;
}
.personalizarInfo .GroupSelect {
	margin-top: 30px;
}
.personalizarInfo .GroupSelect div {
  font-size: 1.1em;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    width: 360px;
    position: relative;
	    margin-bottom: 10px;
    margin: 0 auto;
	margin-bottom: 10px;
}
.personalizarInfo .GroupSelect div:last-of-type {
	margin-top: 30px;
	text-align: center;
	    width: auto;
}
.personalizarInfo .GroupSelect div:last-of-type a {
	    background: #bb121b;
    width: auto;
    display: inline-block;
    padding: 12px;
    position: relative;
    color: white;
    font-size: 1em;
    font-weight: bold;
    min-width: 160px;
}

.personalizarInfo .GroupSelect div:last-of-type a:nth-of-type(1) {
	background: #4E555C;
}
.GroupSelect label {
    padding-left: 35px !important;
	    line-height: 16px;
	    font-size: 13px;
    font-weight: lighter;
    color: #48484C;
}
.GroupSelect input[type=checkbox]:checked + label:before {
    font-size: 26px !important;
}

/*estilos toro*/

.font0 {
	font-size: 0px;
}

.col_15 {
	width: 15%;
	height: 55px;
	display: inline-block;
	vertical-align: top;
}

.col_100 {
	width: 100%;
	height: 55px;
	display: inline-block;
	vertical-align: top;
}

.content_info {
	width: 100%;
	height: auto;
	position: relative;
	background: white;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
	text-align: left;
}

input.input_perfil {
	font-size: 1rem;
	width: 80%;
	border: none;
	outline: none;
	padding: 18px 20px;
	box-sizing: border-box;
	color: #5b5b51;
}

input.input_photo {
	font-size: 1rem;
	width: 100%;
	border: none;
	outline: none;
	padding: 2px 10px;
	margin-left: 40px;
}

.boxInner .disabled {
	opacity: 0.8;
	background: gray;
}

.boxInner .claro p {
	color: #4E555C;
}


/*.gray {
    background: #4E555C;
    display: block;
    height: auto;
    line-height: 25px;
    text-align: left;
    padding: 10px 10px;
    margin: 0px 5px;
}*/

.icon-noimprime {
	border: 3px solid white;
	border-radius: 50%;
	width: 31px;
	height: 30px;
	margin-top: -6px !important;
	left: 18px !important;
}

.gray {
	width: auto;
	background: #4E555C;
	display: block;
	height: auto;
	line-height: 25px;
	text-align: left;
	padding: 10px 19px;
	margin: 6px 5px;
	color: white;
	font-size: 1rem;
	letter-spacing: 1px;
	font-weight: lighter;
}

.btn_imprimir {
	display: block;
	padding: 10px 10px 10px 0px;
	text-align: center;
	background: #bb121b;
	color: #fff;
}

.btn_imprimir span {
	display: inline-block;
	vertical-align: middle;
	font-size: 2.35rem;
}

.btn_imprimir p {
	display: inline-block;
	width: 80%;
	vertical-align: middle;
	margin: 0px;
	font-size: 1.3rem;
}

.gray span {
	font-size: 1rem;
	color: #fff;
}

.col_15 h2 {
	font-size: 1.2rem;
	margin: 0px;
	padding: 5px;
}

.info_btn {
	padding: 13px 10px;
}

.oscuro {
	background: #4E555C;
}

.red {
	background: #bb121b;
	margin: 11px 0px;
}

.claro {
	background: #C2C3C8;
}

.claro p {
	color: #4E555C;
}

::-webkit-scrollbar {
	width: 8px;
	/* for vertical scrollbars */
	height: 8px;
	/* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.32);
}

::-webkit-scrollbar-thumb {
	background: rgba(187, 18, 27, 0.72);
}

-moz-scrollbar {
	-moz-scrollbar-width: 0.10in;
	-moz-scrollbar-border: 1px solid #bb121b;
	-moz-scrollbar-background-color: white;
	-moz-scrollbar-track-background-color: silver;
	-moz-scrollbar-arrow-background-color: silver;
	-moz-scrollbar-arrow-color: rgba(187, 18, 27, 0.47);
}

.info_btn span {
	color: #2f2d2d;
	display: inline-block;
	vertical-align: middle;
	font-size: 4.1rem;
	opacity: 0.5;
}

.info_btn p {
	display: inline-block;
	width: 80%;
	vertical-align: middle;
	margin: 0px;
	font-size: 1.2rem !important;
	color: #FFF;
	padding: 0px 0px 0px 19px;
}

.claro p {
	color: #000;
}

.instrucciones {
	padding: 10px 10px;
	background: #4E555C;
	color: white;
	font-size: 1rem;
	text-align: left;
}

.instrucciones_inner ol {
	width: 85%;
	margin: 20px auto;
}

.instrucciones_inner li {
	padding: 4px 0px;
	font-size: 0.8rem;
}

.content_edit_image {
	width: 100%;
	min-height: 214px;
	background: #fff;
	/* border: 8px solid #C2C3C8; */
	position: relative;
	background-size: contain !important;
	background-repeat: no-repeat !important;
	background-color: #fff !important;
	margin-bottom: 5px;
}

.editar_portada .content_edit_image {
	border: none;
	min-height: inherit;
}

.editar_portada .marco-over {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	height: 100%;
}

.content_edit_image img {
	width: 100%;
}

.check_edit {
	width: 80%;
	position: absolute;
	text-align: center;
	background: rgba(224, 216, 216, 0.82);
	display: block;
	height: auto;
	padding: 10px 0px;
	color: #333;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 18px;
}

.btnDelete {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	right: 10px;
	text-align: center;
	background: rgba(210, 208, 208, 0.83);
	line-height: 30px;
	color: #5b5b5e;
	font-size: 20px;
	font-weight: bold;
}

input[type="checkbox"] {
	margin: 0px 3px;
	border: 0px;
	border-radius: 0px;
}

.edicion {
	width: 520px;
	height: 520px;
	margin: 0px auto;
	border: 10px solid #FFFFFF;
}

.btn_edicion {
	width: 500px;
	height: auto;
	margin: 15px auto;
}

.btn_edicion .boxInner {
	padding: 1px;
}

.btn_edicion .btn_imprimir p {
	font-size: 0.8rem
}

.gris {
	background: #4E555C;
}

.boxes .box {
	padding: 3px;
	display: inline-block;
	float: none;
}

.content_extra {
	max-width: 800px;
	position: relative;
	text-align: center;
	position: relative;
	top: 50%;
	min-height: 60%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	height: auto;
}

.content_extra button {
	background-color: #4b545b;
	display: block;
	position: relative;
	width: 100%;
	color: white;
	font-weight: bold;
	font-size: 13px;
	margin-bottom: 10px;
}

.content_extra .contentDrag {
	background-color: #c4c5c9;
	background-image: url(../images/iconUpload.svg);
	min-height: 320px;
	height: auto;
	padding: 0px 0px;
	background-repeat: no-repeat;
	background-position: center 50px;
	background-size: 19%;
}

.dz-message p {
	margin: 0 !important;
	padding: 0 !important;
	top: 0px;
}

.content_extra .cerrar {
	width: 50px;
	height: 30px;
	position: absolute;
	right: 0px;
	top: 10px;
	font-size: 2rem;
	cursor: pointer;
	background: url("http://feelinbook.com/images/fancybox_sprite.png");
	background-size: 100%;
}

.content_extra .cerrar:hover {
	color: #A01217
}

.inner_content_extra .box2 .box {
	width: 48.5%;
}

.inner_content_extra .box2 .box:first-child {
	margin-right: 10px;
}

.inner_content_extra .box2 .box:last-child {
	margin-left: 10px;
}

.content_edit_image p {
	padding: 0px 10px;
	font-size: 0.8rem;
	line-height: 15px;
	min-height: 40px;
}

.btnCentroEdit div {
	margin: 3px 0px;
}


/*----------------------------
    The Photo
-----------------------------*/

#photo {
	margin: 0 auto;
	width: 500px;
	height: 500px;
	text-align: center;
	position: relative;
}

#photo:after {
	top: 150px;
	text-transform: uppercase;
	z-index: 1;
	color: #ccc;
	font-family: 'Yanone Kaffeesatz', sans-serif;
	display: block;
	font-size: 20px;
	position: absolute;
	width: 100%;
	letter-spacing: 1px;
}

#photo canvas {
	text-align: center;
	display: inline-block;
	border-radius: 15px;
	border: 8px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	position: absolute;
	z-index: 5;
	top: 50%;
	left: 50%;
}

#photo img {
	opacity: 0;
}


/*----------------------------
    The Filters
-----------------------------*/

.btn-primary {
	color: #ffffff;
	background-color: #A01217;
	border-color: #A01217;
}


/*.filters button, .frames button {
    width: 13%;
    display: inline-block;
    margin: 1px;
    text-transform: inherit;
}*/

.filters button,
.frames button {
	width: 140px;
	height: 140px;
	display: inline-block;
	margin: 1px;
	text-transform: inherit;
	background: url(http://feelinbook.com/images/galeria2.jpg);
	background-size: cover;
}


/*.btn {
    display: inline-block;
	width:100%;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent !important;
    white-space: nowrap;
    padding: 10px 18px;
    font-size: 15px;
    line-height: 1.42857143;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	    margin: inherit !important;
}*/

.btn {
	display: inline-block;
	width: 100%;
	min-width: 100px;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent !important;
	white-space: nowrap;
	padding: 15px 28px;
	font-size: 12px;
	line-height: 1.42857143;
	border-radius: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: inherit !important;
}

.instagram-content .check_edit {
	width: 90%;
	position: absolute;
	top: 170px;
	bottom: inherit;
	padding: 10px 10px;
	text-align: center;
	left: 13px;
	background: rgba(255, 255, 255, 0.82);
}

.instagram-content .content_edit_image img {
	height: 223px;
}

.instagram-content .botonesInstagram div,
.instagram-content .botonesInstagram button,
.instagram-content .botonesInstagram a {
	padding: 5px 0px;
	border: 3px solid rgba(255, 255, 255, 0);
	border: 3px solid rgba(255, 255, 255, 0);
	background-clip: padding-box;
	height: 50px;
}

.instagram-content .botonesInstagram div {
	background-color: #9c9ba0;
	color: black;
	font-size: 1em;
	line-height: 33px;
	text-align: center;
}

.instagram-content .botonesInstagram div span {
	font-weight: bold;
}

.instagram-content .botonesInstagram .volver {
	background-color: #4b545b;
	color: white;
	text-align: center;
	font-size: 1.2em;
	font-weight: bold;
	line-height: 33px;
}

.instagram-content .botonesInstagram button {
	color: white;
	background-color: #4b545b;
}

.instagram-content .botonesInstagram button span {
	font-weight: bold;
}

.instagram-content .botonesInstagram button:nth-of-type(2) {
	background-color: #bb121b;
	font-size: 1.2em;
	font-weight: bolder;
}

.instagram-content .botonesInstagram div:nth-of-type(2) {
	color: white;
	font-size: 1.2em;
}

.instagram-content .botonesInstagram div:nth-of-type(2) span {
	font-style: italic;
}

.instagram-content .botonesInstagram button:nth-of-type(4) {
	font-size: 1.5em;
}

.imagenesInstagram aside {
	height: 315px;
}

.imagenesInstagram aside .imgInst {
	position: relative;
}

.imagenesInstagram aside .imgInst div {
	background: rgba(255, 255, 255, 0.61);
	right: 0;
	display: block;
	width: 90%;
	position: absolute;
	bottom: 10px;
	left: 0;
	z-index: 2;
	margin: 0 auto;
	padding: 8px 0;
	text-indent: 5px;
}

.imagenesInstagram aside .descInst {
	padding: 5px 2px;
	bottom: 0;
	position: relative;
	line-height: 14px;
	height: 112px;
	font-size: 12px;
}

.instagram-content .botonesInstagram button:nth-of-type(1) span {
	position: absolute;
	transform: translateY(-32%);
	left: 2%;
	font-size: 30px;
	color: #2f3538;
}


/*checkbox*/

.imagenesInstagram label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 25px;
	margin-right: 15px;
	font-size: 13px;
}

.imagenesInstagram input[type=checkbox] {
	display: none;
}

.imagenesInstagram input[type=checkbox]:checked+ label:before {
	content: "\2713";
	font-size: 18px;
	color: #4B545D;
	text-align: left;
	line-height: 19px;
	border: 2px solid #4B545D;
}

.imagenesInstagram label:before {
	content: "";
	display: inline-block;
	width: 23px;
	height: 23px;
	position: absolute;
	border: 2px solid #4B545D;
	left: 0;
	bottom: -2px;
	background-color: #fff;
	transition: linear 0.1s;
	border-radius: 0;
	padding-right: 22px;
}

.imagenesInstagram .checkbox label {
	color: #48484C;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
	font-size: 13px;
	transition: linear 0.1s;
	padding-left: 30px;
	line-height: 19px;
}

.imagenesInstagram .radio,
.checkbox {
	position: relative;
	width: 180px;
	margin: 0 auto;
}

.imagenesInstagram .checkbox label span {
	font-family: 'fontregularregular';
}

.ui.form> a {
	margin: 20px;
	display: block;
}

.scroll {
	background: rgba(255, 255, 255, 0.8);
	transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
}

//.box.ui-sortable-handle:first-child .content_edit_image {
//	border: 8px solid #BB1203;
//}
.box.ui-sortable-handle:first-child .content_edit_image .check_edit {
	background: #BB1203 !important;
	bottom: -55px;
	top: inherit;
	width: 100%;
	left: 0px;
	color: #fff;
}

.box.ui-sortable-handle:first-child .content_edit_image .check_edit a {
	background: #A01217;
	color: #fff;
}

.box.ui-sortable-handle:first-child .content_edit_image p {
	display: none;
}

#sortable .boxes .box {
	margin-bottom: 50px;
}

.cover-mask {
	z-index: 1;
	position: absolute;
	border: 8px solid #BB1203;
	margin: 0px;
	width: 100%;
	left: 0;
}

.cover-mask-edit {
	z-index: 1;
	position: absolute;
	border: 8px solid #BB1203;
	margin: 0px;
	width: 100%;
	left: 0;
}


/*faq*/

.respuesta p {
	display: inline-block;
}

span.respuesta:before {
	content: "R";
	display: inline-block;
	padding: 10px;
	color: #fff;
	margin: 0px 5px 0px 0px;
	background: #A01217;
	vertical-align: top;
}

.respuesta p {
	display: inline-block;
	max-width: 95%;
	vertical-align: top;
	padding: 0px;
	margin: 0px;
	line-height: 16px;
}

.preguntas:last-of-type {
	margin-bottom: 60px;
}

.wrapper.white {
	background: rgba(255, 255, 255, 0.50);
	padding: 20px 20px 80px;
}

.preguntas strong {
	line-height: 16px;
}

.nosotros p {
	font-size: 20px;
	line-height: 25px;
	font-weight: 300;
}

.nosotros h1 {
	font-size: 40px;
	margin: 0px;
}

.max-letter {
	position: absolute;
	font-size: 10px;
	color: #9a999e;
	padding: 5px 10px;
	top: 10px;
	right: 0px;
	border-left: 1px solid #9a999e;
	z-index: 20;
}

.content_info .max-letter {
	position: absolute;
	font-size: 10px;
	color: #9a999e;
	padding: 5px 10px;
	right: 0px;
	border-left: 1px solid #9a999e;
}

button.ui.button.btn.btGray {
	display: block;
	text-align: center;
	padding: 10px;
	max-width: 30%;
	margin: 10px auto !important;
}

.boxes.box2.content-topBtn {
	width: auto;
	padding: 0px 5px;
	margin: 12px 0px;
}

.box2 .box:first-child {
	margin-right: 14px;
}

.acciones {
	background: #9c9ba0;
	margin: 3px 0px;
	padding: 3px 10px;
}

button.ui.button.btn.btRojo {
	max-width: 30%;
}

nav.filters {
	background: #D7D8DC;
	padding: 20px 0px;
}

.albumes .arrow-left {
	position: absolute;
	z-index: 100;
	left: -63px;
	top: 30px;
	width: 0;
	height: 0;
	width: 0;
	height: 0;
	border-top: 85px solid transparent;
	border-bottom: 95px solid transparent;
	border-right: 60px solid #d7d8dc;
}

.albumes .arrow-right {
	position: absolute;
	z-index: 100;
	right: -63px;
	top: 30px;
	width: 0;
	height: 0;
	border-top: 85px solid transparent;
	border-bottom: 95px solid transparent;
	border-left: 60px solid #d7d8dc;
}

.footerBottom {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.cantidadAlbumes {
	height: auto;
	padding: 5px 10px;
}

.cantidadAlbumes a {
	background: #bb121b;
	padding: 16px;
	margin-top: 10px;
	color: white;
	text-align: center;
	position: relative;
	bottom: 0;
	margin: 0;
	font-weight: bold;
	font-size: 14px;
}

.cantidadAlbumes div {
	height: 100%;
	border-radius: 2px;
	line-height: 35px;
	font-size: 17px;
	margin-bottom: 10px;
	padding: 0;
	padding-right: 11px;
}

.cantidadAlbumes .icon-triangulo {
	position: absolute;
	left: 30px;
	top: 12px;
	font-size: 20px;
}

.cantidadAlbumes div:after {
	content: "";
	width: 100%;
	height: 4px;
	border-radius: 2px;
	background-color: #52565D;
	display: block;
}

nav.filters {
	background: #D7D8DC;
	padding: 20px 20px;
	position: relative;
}

.boxes.box2.centrar {
	text-align: center;
	margin: 20px 0px;
}

.col_30 h2 {
	font-size: 24px;
	margin: 0px 20px;
	text-align: right;
}

.col_30 {
	width: 30%;
	display: inline-block;
	vertical-align: top;
}

.col_70 {
	width: 70%;
	display: inline-block;
	vertical-align: top;
	margin: 0px 0px 10px;
}

.col_70 input.input_photo {
	font-size: 1rem;
	width: 100%;
	height: 100px;
	border: none;
	outline: none;
	padding: 2px 10px;
	margin-left: 0px;
}

.frames a.btn.btn-warning {
	width: 32.2%;
	height: 280px;
	display: inline-block;
	background: rgb(255, 255, 255);
	margin: 2px 5px !important;
	border: 5px solid #D7D8DC !important;
}

.frames .active {
	border: 5px solid #bb121b !important;
}

.form-group {
	margin: 20px 0px;
}

.form-group.has-error p {
	font-weight: 300;
	font-size: 14px;
}

.boxInner p {
	color: #FFF;
	font-size: 1rem;
	min-height: 20px;
}

.content-center {
	width: auto;
	position: absolute;
	top: 50%;
	padding: 50px 10px;
	margin-top: -180px;
	background: rgba(255, 255, 255, 0.50);
	left: 50%;
	margin-left: -100px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input.btn.btRojo {
	font-size: 0.8rem;
}

.content_edit_image p {
	color: #000;
	font-size: 0.9rem;
}

.content-description {
	position: relative;
}

.content-description p {
	color: #48484C;
	margin: 0;
	font-size: 12px;
	line-height: 16px;
	padding: 0px 4px;
}

label.label2 {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.boxes.content-topBtn {
	box-sizing: border-box;
	width: 100%;
	padding: 6px;
}

#upload_file> div:first-of-type {
	overflow: scroll;
	height: 560px;
}

textarea {
	overflow: auto;
	resize: none;
	width: 100% !important;
	border: 1px solid #D7D8DC;
	font-family: 'Open Sans', sans-serif !important;
}

.loader {
	background: rgba(255, 255, 255, 0.89);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99;
}

.spinner {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	padding-bottom: 10px;
	margin: 0 auto;
	text-align: center;
}

.spinner> div {
	width: 18px;
	height: 18px;
	background-color: #c72727;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.editPefil {
	height: auto;
	margin-top: 4%;
	margin-bottom: 6%;
}

.editPefil article:nth-of-type(1) {
	width: 100%;
	padding: 6px 20px;
	border-bottom: 4px solid gray;
	border-radius: 2px;
}

.editPefil article h3 {
	margin: 0;
	font-weight: lighter;
}

.editPefil article p {
	margin: 0;
}

.editPefil article:nth-of-type(2) {
	background-color: #d8dade;
	list-style: none;
	padding: 11px 10px;
	margin-top: 20px;
}

.editPefil article:nth-of-type(2) ul {
	margin: 0;
	padding: 0px 13px;
	line-height: 18px;
}

.editPefil article:nth-of-type(2) ul li {
	list-style: none;
}

.editPefil article:nth-of-type(3) {
	padding: 11px 10px;
	margin-top: 20px;
	height: auto;
	min-height: 140px;
}

.editPefil article:nth-of-type(3) div {
	margin: 0;
}

.editPefil article:nth-of-type(3) p {
	padding-left: 15px;
}

.editPefil article:nth-of-type(3) div input {
	width: 90%;
	margin: 5px 0px;
	padding: 10px 10px;
	border: none;
	background: white;
	font-size: 13px;
}

.editPefil article:nth-of-type(4) {
	border-bottom: 2px solid gray;
	border-top: 2px solid gray;
	padding: 0px 10px 10px 10px;
}

.editPefil article:nth-of-type(4) form {
	padding: 13px 0px 32px 0px;
	border-bottom: 2px solid #9da3ab;
	border-top: 2px solid #9da3ab;
	margin: 0;
}

.editPefil article:nth-of-type(4) div input,
.editPefil article:nth-of-type(4) div select {
	border: 0;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.editPefil article:nth-of-type(4) form label {
	font-weight: lighter;
	font-size: 1rem;
}

.editPefil article:nth-of-type(4) form .form-group {
	margin: 5px 0px;
}

.editPefil button {
	width: auto;
	height: 50px;
	background-color: #bb121b;
	color: white;
	margin: 50px 0px;
	position: relative;
	text-align: center;
	font-size: 1rem;
	padding: 14px 61px;
	margin-top: 40px;
	margin-left: 16px;
	display: inline-block;
}
.txtlomo {
	font-size: 12px;
    padding-left: 18px;
}

.agregarDireccion .address ul {
	border: none;
	background: white;
}

.agregarDireccion {
	max-width: 670px;
	margin-top: 6%;
	margin-bottom: 6%;
}

.agregarDireccion article aside:nth-of-type(1) div {
	display: inline-block;
	height: 57px;
	padding: 0;
	margin-top: 10px;
}

.agregarDireccion article aside:nth-of-type(1) div:nth-of-type(1) {
	width: 80%;
	background: white;
	padding-left: 20px;
	font-size: 1rem;
	line-height: 55px;
}

.agregarDireccion article aside:nth-of-type(1) div:nth-of-type(2) {
	width: 19%;
	float: right;
	background: #bb121b;
	text-align: center;
	line-height: 13px;
	padding-top: 15px;
}

.agregarDireccion .numeroFotos span {
	display: block;
	font-weight: bold;
	margin-bottom: 3px;
	font-size: 16px;
}

.agregarDireccion .numeroFotos {
	background: #bb121b;
	color: #fff;
	box-shadow: none;
}

.agregarDireccion article aside {
	padding: 0px 3px;
}

.agregarDireccion article aside:nth-of-type(2) p {
	background: #52565D;
	text-align: center;
	color: white;
	font-weight: bold;
	padding: 13px 0px;
	margin: 0;
}

.direcciones {
	height: auto;
	width: 100%;
	position: relative;
	min-height: 30px;
}

.cerrarDirecciones {
	padding: 0px 4px;
	background: url(../images/btnBorrarDirec.svg);
	color: white;
	background-size: 100%;
	background-repeat: no-repeat;
	position: absolute;
	right: 5px;
	font-size: 1.6em;
	cursor: pointer;
    height: 20px;
    width: 20px;
}

.editarDirecciones {
	padding: 0px 4px;
	background: url(../images/btnEditarDirec.svg);
	color: white;
	background-size: 100%;
	background-repeat: no-repeat;
	position: absolute;
	right: 5px;
	font-size: 1.6em;
	cursor: pointer;
    height: 20px;
    width: 20px;
	margin-top: 29px;
}

.album-title-cover {
	    position: absolute;
    width: 300px;
    transform: rotate(-90deg);
    padding: 15px;
    transform-origin: left bottom 0;
    top: inherit;
    margin-top: 258px;
    left: 42px;
    color: gray;
    background: white;
}

.editar_portada .centerBlock {
	width: 360px;
	position: relative;
	margin: 0 auto;
	height: 300px;
}

.direcciones ul {
	padding: 6px 0px;
	margin: 5px 0px;
	border-bottom: 1px solid gray;
}

.direcciones ul li {
	list-style: none;
	padding: 3px 40px 3px 10px;
	margin: 0;
}

.direcciones button {
	background: #bb121b;
	display: block;
	text-align: center;
	padding: 13px;
	margin-top: 10px;
	color: white;
	width: 100%;
}

.direcciones button:hover {
	opacity: 0.8;
}

.direcciones button:after {
	content: "+";
	position: absolute;
	right: 15px;
	width: 15px;
	height: 15px;
	font-size: 23px;
	margin-top: -7px;
}

.agregarDireccion article aside:nth-of-type(2) a {
	background: #bb121b;
	display: block;
	text-align: center;
	padding: 13px;
	margin-top: 10px;
	color: white;
	font-size: 0.8rem;
}


.agregarDireccion article aside:nth-of-type(2) a:hover {
	opacity: 0.8;
}

.agregarDireccion article aside:nth-of-type(2) a:nth-of-type(2) {
	background: #52565D;
}

.agregarDireccion article aside:nth-of-type(2) a:nth-of-type(1) {
	margin-top: 20px;
}

.dropzone .dz-preview .dz-progress {
	background: rgba(232, 67, 67, 0.9) !important;
}

.paymentSucces {
	width: 670px;
	margin: 0 auto;
	position: relative;
	text-align: center;
}

.paymentSucces p {
	background: #bb121b !important;
	padding: 15px;
	display: block;
	width: 100%;
	color: white;
	font-size: 0.8rem;
	font-weight: bold;
}

.paymentSucces a {
	font-weight: bold;
	color: #2d2d2d;
}


/*mis pedidos*/

.infoMispedidos ul {
	padding-left: 10px;
	color: #160E0C;
	font-size: 1.1em;
	line-height: 18px;
	margin: 5px 0px;
}

.infoMispedidos ul li {
	list-style: none;
}

.mispedidosTitle {
	margin-bottom: 20px;
	line-height: 40px;
	font-size: 1.5em;
}

.mispedidosTitle:after {
	content: "";
	width: 100%;
	height: 4px;
	border-radius: 2px;
	background-color: #52565D;
	display: block;
}

.mispedidos aside {
	margin-bottom: 30px;
}

.btnMispedidos {
	text-align: center;
}

.mispedidos .btnMispedidos a {
	text-align: center;
	font-size: 12px;
	color: white;
	background-color: #9c9ba0;
	padding: 8px;
	line-height: 15px;
	height: 47px;
	width: 47%;
	margin: 1%;
	display: inline-block;
}

.btnMispedidosAprobado {
	background-color: #bb121b !important;
}

.infopedidos ul li {
	list-style: none;
	font-size: 1.1em;
	text-align: left;
	padding-left: 0px;
	line-height: 29px;
}

.infopedidos ul {
	padding: 0;
}

.infopedidos div:nth-of-type(1) {
	border-bottom: 1px solid gray;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.inner-portada {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	z-index: 10;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: white;
	z-index: 99;
}

html,
body {
	overflow-x: hidden;
}

.container {
	overflow-x: hidden;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.container.overlay-open {
	-webkit-transform: translateX(50%);
	transform: translateX(50%);
}

.container::after {
	content: '';
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
}

.container.overlay-open::after {
	visibility: visible;
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.overlay-contentpush {
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: -webkit-transform 0.5s, visibility 0s 0.5s;
	transition: transform 0.5s, visibility 0s 0.5s;
}

.overlay-contentpush .form-group {
	margin-bottom: 15px;
	padding: 0px 5px;
	border-radius: 0px;
}

.overlay-contentpush input,
.overlay-contentpush select {
	padding: 6px 10px;
	border: 1px solid #c7c1c1;
	outline: none;
	border-radius: 0px !important;
	height: 38px;
	margin: 3px;
	width: calc(100% / 2.1);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.overlay-contentpush.open {
	visibility: visible;
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.overlay-contentpush article {
	max-width: 800px;
	position: relative;
	top: 50%;
	height: auto;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	padding-bottom: 10px;
}

.overlay-contentpush article div:nth-of-type(1) {
	height: auto;
	margin-bottom: 11px;
	width: 98.5%;
}

.overlay-contentpush article form {
	padding: 0;
	text-align: center;
	margin-bottom: 7px;
}

.overlay-contentpush article div:nth-of-type(1) p {
	padding: 0;
	display: inline-block;
	padding-left: 15px;
}

.overlay-contentpush article div:nth-of-type(1) button {
	color: white;
	display: inline-block;
	float: right;
	right: 28px;
	width: 205px;
}

.overlay-contentpush #add-address {
	background-color: #bb121b;
	font-size: 0.9rem;
	padding: 6px;
	width: 97%;
	position: relative;
	display: block;
}

.overlay-contentpush #addr-error {
	padding: 0;
	height: auto;
	color: red;
	display: block;
	margin: 0;
	padding-left: 13px;
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}

img.album-cover {
	position: absolute;
	top: 0px;
}

input.inputError,
select.inputError {
	border: 1px solid #bb121b;
	;
}

.editar_portada .canvas-container {
	width: 300px !important;
	height: 300px !important;
}

.editar_portada #canvas {
	width: 300px !important;
	height: 300px !important;
}

.editar_portada .canvas-container .upper-canvas {
	width: 300px !important;
	height: 300px !important;
}

.info-cover {
	text-align: center;
}

.cont_cup input {
    width: 100%;
    margin: 0px 0px 10px 0px;
    font-size: 1.3em;
	outline: none;
    padding: 8px 25px;
	text-align: center;
}
.cont_cup span {
	font-size: 1em;
    text-align: center;
    margin: 10px;
    position: relative;
    display: block;
}

.list-transaccion li {
    line-height: 15px;
    background: #f9f9f9;
    padding: 10px 10px !important;
}

.code-message {
	color: red !important;
}