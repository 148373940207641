@media only screen and (max-width: 1120px) {
	.adorno {
		display: none
	}
}

@media only screen and (max-width: 940px) {
	.editPefil article:nth-of-type(3) div input {
		width: 100%;
		margin: 10px 0px;
	}
	.editPefil article:nth-of-type(3) div {
		padding: 0;
	}
	.btMenu {
		display: block;
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: 2.6rem;
		cursor: pointer
	}
	header nav {
		display: none;
		float: inherit;
		text-align: center;
	}
	header {
		background: #fff;
		min-height: 80px;
	}
	header .logo {
		width: 180px;
	}
	.banner1,
	.ingresar {
		margin-top: inherit;
	}
	footer {
		padding: 10px;
	}
	.logo.logoRegistro {
		height: auto;
	}
}

@media only screen and (max-width: 768px) {
	.albumes .boxes {
		margin-top: 30px;
	}
	.carruselRevision .arrow-left {
    left: -20px;
	}
	.carruselRevision .arrow-right {
    right: -17px;
	}
	.carruselRevision .botonesRev2 {
    width: 100%;
	}
	.album-title-cover {
    left: 32px;
	}
	.editar_portada .centerBlock {
    width: 340px;
	}
	.carruselRevision {
     width: 100% !important; 
	}
	.revision_imagen article:nth-of-type(1) aside .botonesRevision a:nth-of-type(1) {
		background-image: none;
	}
	.galeria {
		text-align: center;
	}
	.content_edit_image {
		min-height: 157px;	
	}
	.revision_imagen {
		height: 800px;
	}
	.albumes {
		padding: 0px 15px;
	}
	.albumes.container {
		padding: 0;
	}
	.info_btn span {
		font-size: 2.1rem;
	}
	.info_btn p {
		font-size: 1rem !important;
	}
	.imagenesInstagram label {
		width: 80%;
	}
	.personalizarInfo .GroupSelect div:last-of-type a {
		min-width: 110px;
	}
	.albumes button.ui.button.btn.btRojo {
		width: auto;
		font-size: 0.8em;
	}
	.infopedidos button {
		color: white;
		float: none !important;
		right: 28px;
		width: 100% !important;
		display: block;
	}
	.overlay-contentpush article div:nth-of-type(1) p {
		padding: 0;
	}
	.imagenesInstagram aside {
		height: 285px;
		padding: 3px;
	}
	.cantidadAlbumes div:nth-of-type(1) {
		padding-right: 0;
	}
	.album .numeroFotos {
		box-shadow: 0 0 0 2px #52565D inset;
		font-size: 10px;
	}
	.album .btn {
		padding-left: 44px;
	}
	.imagenesInstagram .checkbox label {
		font-size: 9px;
		padding-left: 28px;
		text-align: left;
		line-height: 24px;
	}
	.imagenesInstagram aside .descInst p {
		margin: 0;
	}
	.box4 .box {
		padding: 6px;
		margin-bottom: 40px;
	}
	.box3 .box {
		margin-bottom: 10px;
	}
	header nav li {
		display: block;
		margin: 5px 0px;
	}
	.ingresar .wrap {
		margin: 50px auto
	}
	.acciones a {
		width: 48%
	}
	.album .info {
		width: 75%;
	}
}

@media only screen and (max-width: 640px) {
	.galeria a {
		width: 48%;
	}
	.banner2 span {
		font-size: 4rem;
	}
	.banner2 p {
		font-size: 0.8rem;
	}
	footer .footIz {
		display: block;
		position: relative;
		margin: 0 auto;
		text-align: center;
	}
	footer .logoMercurio {
		float: inherit;
		margin: 20px auto;
	}
	.swiper-slide img {
		width: 80%;
		display: block;
		text-align: center;
		margin: 0px auto;
	}
	.banner2 .pagination {
		right: inherit;
		width: 100%;
		bottom: 0px;
		top: inherit;
	}
	span.swiper-pagination-switch {
		margin: 3px 3px;
	}
	.swiper-slide img {
		width: 80% !important;
		display: block;
		margin: 0px auto;
	}
	.inner_content_extra {
		width: 100%;
		height: auto;
		/* min-height: 500px; */
		position: relative;
		margin: 100px auto 0px;
		/* background: #565656; */
	}
	.inner_content_extra .box2 .box:last-child {
		margin-left: inherit;
	}
}