/*
 * Swiper - Mobile Touch Slider CSS
 * http://www.idangero.us/sliders/swiper
 *
 * Vladimir Kharlampidi, The iDangero.us
 * http://www.idangero.us/
 * 
 * Copyright 2012-2013, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us
 *
 * Licensed under GPL & MIT
 *
*/

/* ===============================================================
Basic Swiper Styles 
================================================================*/
.swiper-container {
	margin:0 auto;
	position:relative;
	overflow:hidden;
	-webkit-backface-visibility:hidden;
	-moz-backface-visibility:hidden;
	-ms-backface-visibility:hidden;
	-o-backface-visibility:hidden;
	backface-visibility:hidden;
	/* Fix of Webkit flickering */
	z-index:1;
}
.swiper-wrapper {
	position:relative;
	width:100%;
	-webkit-transition-property:-webkit-transform, left, top;
	-webkit-transition-duration:0s;
	-webkit-transform:translate3d(0px,0,0);
	-webkit-transition-timing-function:ease;
	
	-moz-transition-property:-moz-transform, left, top;
	-moz-transition-duration:0s;
	-moz-transform:translate3d(0px,0,0);
	-moz-transition-timing-function:ease;
	
	-o-transition-property:-o-transform, left, top;
	-o-transition-duration:0s;
	-o-transform:translate3d(0px,0,0);
	-o-transition-timing-function:ease;
	-o-transform:translate(0px,0px);
	
	-ms-transition-property:-ms-transform, left, top;
	-ms-transition-duration:0s;
	-ms-transform:translate3d(0px,0,0);
	-ms-transition-timing-function:ease;
	
	transition-property:transform, left, top;
	transition-duration:0s;
	transform:translate3d(0px,0,0);
	transition-timing-function:ease;
}
.swiper-free-mode > .swiper-wrapper {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	margin: 0 auto;
}
.swiper-slide {
	float: left;
	position:relative;
}
.banner1 .swiper-slide, .banner1 .swiper-wrapper{
	height:100vh !important
}
.swiper-slide img{
	width:100%
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	-ms-touch-action: pan-y;
}
.swiper-wp8-vertical {
	-ms-touch-action: pan-x;
}

/* ===============================================================
Your custom styles, here you need to specify container's and slide's
sizes, pagination, etc.
================================================================*/
.swiper-container {
	/* Specify Swiper's Size: */

	/*width:200px;
	height: 100px;*/
}
.swiper-slide {
	/* Specify Slides's Size: */
	
	/*width: 100%;
	height: 100%;*/
}
.swiper-slide-active {
	/* Specific active slide styling: */
	
}
.swiper-slide-visible {
	/* Specific visible slide styling: */	

}
/* ===============================================================
Pagination Styles
================================================================*/
.swiper-pagination-switch {
	/* Stylize pagination button: */	

}
.swiper-active-switch {
	/* Specific active button style: */	
	
}
.swiper-visible-switch {
	/* Specific visible button style: */	
	
}
.arrow-left {
    background: url(../images/arrow.png);
    left: 0px;
    right: auto;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    width: 112px;
    height: 55px;
    margin-top: -30px;
    z-index: 1;
    cursor: pointer;
    background-repeat: no-repeat;
    z-index: 9;
}
.arrow-right{
	 background: url(../images/arrow.png) -112px 0px;
  left: auto;
  right: 0px;
	
  opacity: 0.5;
  position: absolute;
  top: 50%;
  top: 50%;
    width: 112px;
    height: 55px;
    margin-top: -30px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  z-index: 9;
}
.slider_producto .arrow-left {
    background: url(../images/arrow_producto.jpg);
    left: 10px;
    right: auto;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    width: 112px;
    height: 55px;
    margin-top: -30px;
    z-index: 1;
    cursor: pointer;
    background-repeat: no-repeat;
    z-index: 9;
}
.slider_producto .arrow-right{
	 background: url(../images/arrow_producto.jpg) -112px 0px;
  left: auto;
  right: 10px;
	
  opacity: 0.5;
  position: absolute;
  top: 50%;
  top: 50%;
    width: 112px;
    height: 55px;
    margin-top: -30px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  z-index: 9;
}

.pagination{
	  position: absolute;
  z-index: 9;
  bottom: 5px;
  text-align: center;
  width: 100%;
  padding: 10px;
}
.swiper-pagination-switch {
  display: inline-block !important;
  padding: 0px !important;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #fff;
  margin: 0 4px;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  letter-spacing: 0px;
  border:solid 1px #c00e30
}
.swiper-active-switch {
  background: #c00e30 !important;
  width: 15px !important;
  height: 15px !important;
   border:solid 1px #c00e30
}





